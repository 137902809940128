<template>
    <div class="row">
        <div class="col-12">
            <div class="form-group">
                <label for="">First Name</label>
                <input type="text" class="form-control"
                       @change="$emit('change',{key:'first_name','value':input.first_name})"
                       v-model="input.first_name"/>
                <span class="text-danger">{{getError('first_name')}}</span>
            </div>
            <div class="form-group">
                <label for="">Last Name</label>
                <input type="text" class="form-control"
                       @change="$emit('change',{key:'last_name','value':input.last_name})" v-model="input.last_name"/>
                <span class="text-danger">{{getError('last_name')}}</span>
            </div>
            <div class="form-group">
                <label for="">Company</label>
                <input type="text" @change="$emit('change',{key:'company','value':input.company})" class="form-control"
                       v-model="input.company"/>
                <span class="text-danger">{{getError('company')}}</span>
            </div>

            <div class="form-group">
                <label for="">Phone</label>
                <input type="text" @change="$emit('change',{key:'phone','value':input.phone})" class="form-control"
                       v-model="input.phone"/>
                <span class="text-danger">{{getError('phone')}}</span>
            </div>
            <div class="form-group">
                <label for="">Email</label>
                <input type="email" @change="$emit('change',{key:'email_address','value':input.email_address})" class="form-control"
                       v-model="input.email_address"/>
                <span class="text-danger">{{getError('email')}}</span>
            </div>
            <State :error="getError('state_id')" v-model="input.state_id"></State>
            <City v-if="input.state_id>0" :error="getError('city_id')" :state_id="input.state_id" v-model="input.city_id"></City>
            <ZipCode v-if="input.state_id>0&&input.city_id>0" v-model="input.zip_code_id" :error="getError('zip_code_id')" :state_id="input.state_id" :city_id="input.city_id"></ZipCode>

            <div class="form-group">
                <label for="">Street Address</label>
                <input type="text" @change="$emit('change',{key:'street_address1','value':input.street_address1})"
                       class="form-control" v-model="input.street_address1"/>
                <span class="text-danger">{{getError('street_address1')}}</span>
            </div>
            <div class="form-group">
                <!--<label for="">First Name</label>-->
                <input type="text" @change="$emit('change',{key:'street_address2','value':input.street_address2})"
                       class="form-control" v-model="input.street_address2"/>
                <span class="text-danger">{{getError('street_address2')}}</span>
            </div>

        </div>
    </div>
</template>
<script>
    import lists from '@/mixins/list.js';

    import State from '@/views/components/dropdown/State';
    import City from '@/views/components/dropdown/City';
    import ZipCode from '@/views/components/ZipCode';

    export default{
        mixins: [lists],
        props: ['address', 'errors'],
        components:{State,City,ZipCode},
        data(){
            return {


                input: {
                    first_name: '',
                    last_name: '',
                    company: '',
                    street_address1: '',
                    street_address2: '',
                    state_id: 0,
                    zip_code_id: 0,
                    city_id:0,
                    phone: '',
                    email_address: '',
                },

            }
        },
        methods: {
            loadStates(){
                this.states = this.statesList().then(response => this.states = response.data);
            },

            loadDefault(){
                this.input.first_name = this.address.first_name;
                this.input.last_name = this.address.last_name;
                this.input.email_address = this.address.email_address;
                this.input.phone = this.address.phone;

                this.input.company = this.address.company;
                this.input.state_id = this.address.state_id;
                this.input.street_address1 = this.address.street_address1;
                if (this.address.zip_code == undefined) {
                    return;
                }
                this.zip_code = this.address.zip_code.code;
            },
            getError(key){

                if (!this.errors['shipping_address.' + key]) {
                    return;
                }

                return this.errors['shipping_address.' + key][0];

            }

        },
        watch: {

            input:{
                handler:function () {
                    this.$emit('input',this.input);
                },deep:true,
            },

            address: {
                handler: function () {
                    console.log('Address changed');
                    this.loadDefault();

                },
                deep: true,

            },

        },

        mounted()
        {

            this.loadDefault();

        }
    }
</script>