<template>
    <div class="form-group">
        <label for="">State</label>
        <v-select :options="states" v-model="state" @search="search_state"/>
        <span class="text-danger">{{error}}</span>
    </div>
</template>
<script>

    import {Paths} from '@/paths'
    export default{
        props:['error'],
        data(){
            return{
                states:[],
                state:null,
            }
        },
        methods:{
            search_state(query){
                let app=this;
                this.axios.post(Paths.state.search,{name:query}).then(function (response) {
                    app.states=[];
                    app._.forEach(response.data,function (state) {
                        app.states.push({'code':state.id,'label':state.name});
                    })

                });
            }
        },
        watch:{
            state:function (state) {
                if(state==null){
                    this.$emit('input',0);
                    return;
                }
                this.$emit('input',state.code);
            }
        },


    }
</script>