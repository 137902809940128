<template>
    <div class="form-group">
        <label for="">Zip Code</label>
        <v-select :options="codes" v-model="code" @search="search_codes"/>
        <span class="text-danger" >{{error}}</span>
    </div>
</template>
<script>
    import {Paths} from '@/paths';
    export default{
        props:['state_id','city_id','error'],
        data(){
            return {
                codes:[],
                code:null,
            }
        },
        methods:{
            search_codes(query){
                let app=this;

                this.axios.post(Paths.zip_code.search,{code:query,state_id:app.state_id,city_id:app.city_id}).then(function (response) {
                    app.codes=[];
                    app._.forEach(response.data,function (code) {
                        app.codes.push({'code':code.id,'label':code.code});
                    })

                });
            }

        },
        watch:{
            code:function (new_zip) {
                if(new_zip==null){
                    this.$emit('input',0);
                    return;
                }



                this.$emit('input',new_zip.code);
            }
        }

    }
</script>