<template>
    <div class="form-group">
        <label for="">City</label>
        <v-select :options="cities" v-model="city" @search="search_city"/>
        <span class="text-danger">{{error}}</span>
    </div>
</template>
<script>

    import {Paths} from '@/paths'
    export default{
        props:['error','state_id'],
        data(){
            return{
                cities:[],
                city:null,
            }
        },
        methods:{
            search_city(query){
                let app=this;
                this.axios.post(Paths.city.search,{name:query,state_id:app.state_id}).then(function (response) {
                    app.cities=[];
                    app._.forEach(response.data,function (city) {
                        app.cities.push({'code':city.id,'label':city.city});
                    })

                });
            }
        },
        watch:{
            city:function (city) {
                if(city==null){
                    this.$emit('input',0);
                    return;
                }
                this.$emit('input',city.code);
            }
        },


    }
</script>