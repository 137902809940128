<template>
    <div>
        <div class="container-fluid pl-5 pr-5 content-container mt-5">

            <!--<add :path="url.add" v-on:added="newRecordAdded"></add>-->

            <!--<edit :path="url.update" :customer="customer" v-on:updated="recordUpdated"></edit>-->
            <!--<detail :customer="customer"></detail>-->
            <!--<div class="row">-->
                <!--<div class="col-12 bg-light shadow p-3 mb-3 ">-->
                    <!--<p class="heading">Filters</p>-->
                    <!--<hr/>-->
                    <!--<keyword-filter :url="url.filter" :search_types="{1:'First Name',2:'Email','3':'Phone'}"-->
                                    <!--v-on:filtered="setFilteredData" v-on:clear="fetchData"></keyword-filter>-->
                <!--</div>-->
            <!--</div>-->
            <axios :url="url" :request="request" :data="data" :trigger="trigger" v-on:success="handleCustomerRequest"
                   v-on:error="setErrors($event.response)"
                   v-on:request="trigger=false;"></axios>

            <div class="row">
                <div class="col-sm-12 bg-light shadow p-3 text-left ">


                    <p class="heading">Add Customer</p>
                    <hr>

                        <div class="row">
                            <div class="col-sm-12">
                                <div class="form-group">
                                    <label for="">Company Name</label>
                                    <input type="text" class="form-control"
                                           v-model="input.company">
                                    <span class="text-danger">{{getError('company')}}</span>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-6">
                                <div class="form-group">
                                    <label for="">Phone</label>
                                    <input type="text" class="form-control" v-model="input.phone">
                                    <span class="text-danger">{{getError('phone')}}</span>
                                </div>
                            </div>
                            <div class="col-sm-6">
                                <div class="form-group">
                                    <label for="">Email</label>
                                    <input type="email" class="form-control"
                                           v-model="input.email">
                                    <span class="text-danger">{{getError('email')}}</span>
                                </div>
                            </div>

                        </div>
                        <!--<div class="row">-->
                            <!--<div class="col-sm-12">-->
                                <!--<div class="form-group">-->
                                    <!--<label for="">Address</label>-->
                                    <!--<input type="text" class="form-control" v-model="input.address">-->
                                    <!--<span class="text-danger">{{getError('address')}}</span>-->
                                <!--</div>-->
                            <!--</div>-->
                        <!--</div>-->
                        <div class="row mb-4">
                            <div class="col-sm-6">
                                <label>Company Type</label>
                                <select v-model="input.account_type" id="" class="form-control">
                                    <option value="0">Select Account Type</option>
                                    <option v-for="(account,index) in account_types" :key="index" :value="index">
                                        {{account}}
                                    </option>
                                </select>
                                <span class="text-danger">{{getError('account_type')}}</span>
                            </div>
                            <div class="col-sm-6" v-if="showTI">
                                <label for="">EI Number</label>
                                <input type="text" class="form-control" v-model="input.ti_number">
                                <span class="text-danger">{{getError('ti_number')}}</span>
                            </div>
                            <div class="col-sm-6" v-if="showExempt">
                                <label for="">Exemption No</label>
                                <input type="text" class="form-control" v-model="input.exempt_number">
                                <span class="text-danger">{{getError('exempt_number')}}</span>
                            </div>

                        </div>
                        <p class="heading">Contact Person</p>
                        <hr>
                        <div class="row">
                            <div class="col-sm-6">
                                <div class="form-group">
                                    <label for="">First Name</label>
                                    <input type="text" class="form-control" v-model="input.first_name"
                                           @change="updateAddress('first_name')">
                                    <span class="text-danger">{{getError('first_name')}}</span>
                                </div>
                            </div>
                            <div class="col-sm-6">
                                <div class="form-group">
                                    <label for="">Last Name</label>
                                    <input type="text" class="form-control" v-model="input.last_name"
                                           @change="updateAddress('last_name')">
                                    <span class="text-danger">{{getError('last_name')}}</span>
                                </div>
                            </div>
                        </div>

                        <p class="heading">Shipping Address</p>
                        <hr>

                        <shipping-address v-model="input.shipping_address" :errors="errors"
                                          :address="input.shipping_address"></shipping-address>

                        <p class="heading">Billing Address</p>
                        <hr>
                        <div class="form-group">
                            <input type="checkbox" v-model="input.is_same_as_shipping"> &nbsp; Same as shipping address
                        </div>

                        <billing-address v-if="!input.is_same_as_shipping" :errors="errors"
                                        v-model="input.billing_address"
                                         :address="input.billing_address"></billing-address>

                        <p class="heading">Account credentials</p>
                        <hr>
                        <div class="row">
                            <div class="col-sm-6">
                                <div class="form-group">
                                    <label for="">User Name</label>
                                    <input type="text" class="form-control" v-model="input.user_name">
                                    <span class="text-danger">{{getError('user_name')}}</span>
                                </div>
                            </div>
                            <div class="col-sm-6">
                                <div class="form-group">
                                    <label for="">Password</label>
                                    <input type="text" class="form-control" v-model="input.password">
                                    <span class="text-danger">{{getError('password')}}</span>
                                </div>

                            </div>

                        </div>



                        <div v-if="loading">
                            <pulse-loader :loading="loading"></pulse-loader>
                        </div>



                    <div v-if="loading">
                        <pulse-loader :loading="loading"></pulse-loader>
                    </div>
                    <div class="row" v-else="">
                        <div class="col-sm-12">
                            <input type="button" @click="store" class="btn btn-outline-info " value="Save">
                            &nbsp;
                            <router-link :to="{name:'customer'}" class="btn btn-danger">Cancel</router-link>

                        </div>

                    </div>
                </div>
                </div>

            </div>
        </div>


</template>
<script>
    import forms from '@/forms';
    import {Paths} from '../../paths'
    import axios from  '@/views/actions/Axios';
    import BillingAddress from '@/views/address/billing/Form';
    import ShippingAddress from '@/views/address/delivery/Form';
    export default{
        extends: forms,
        components:{axios,ShippingAddress, BillingAddress},
        data(){
            return {
                account_types: {
                    1: 'Childcare center',
                    2: 'Restaurant',
                    3: 'Schools',
                    4: 'Non profits',
                    5: 'Families',
                },
                input:{
                    first_name: '',
                    last_name: '',
                    user_name: '',
                    password: '',
                    password_confirmation: '',
                    email: '',
                    phone: '',
                    address: '',
                    company: '',
                    account_type: 0,

                    shipping_address: {
                        first_name: '',
                        last_name: '',
                        email_address: '',
                        phone: '',
                        state_id: '',
                        zip_code_id: '',
                        street_address1: '',
                        company: '',

                    },
                    is_same_as_shipping: true,
                    billing_address: {
                        first_name: '',
                        last_name: '',
                        email_address: '',
                        phone: '',
                        state_id: '',
                        zip_code_id: '',
                        street_address1: '',
                        company: '',

                    }
                }
                , success: false,
                data:{},
                url:'',
                trigger:false,
                request:'post',

            }
        },
        computed: {
            showTI: function () {
                if (this.input.account_type < 4 && this.input.account_type > 0) {
                    return true;
                }
                return false;
            },
            showExempt: function () {
                if (this.input.account_type == 4) {
                    return true;
                }
                return false;
            }
        },methods: {
            updateAddress(key)
            {
                let address_key = key;
                if (address_key == 'email') {
                    address_key = 'email_address';
                }
                if (this.input.shipping_address[address_key] !== undefined) {
//                    if (this.input.shipping_address[address_key].length == 0) {
                        this.input.shipping_address[address_key] = this.input[key];
//                    }
                }

                if (this.input.billing_address[address_key] !== undefined) {
//                    if (this.input.billing_address[address_key].length == 0) {
                        this.input.billing_address[address_key] = this.input[key];
//                    }
                }


            },
            handleCustomerRequest(){
                this.$router.push({ name: 'customer' })
            },
//            shippingAddressUpdated(data){
//
//                this.input.shipping_address=data;
//            },
//            billingAddressUpdated(data){
//                this.input.billing_address=data;
//            },

            store(){
                this.data=this.input;
                this.trigger=true;
            }
        },
        mounted(){
            this.url = Paths.customer.add;

        }
    }
</script>